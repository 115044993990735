<template>
  <div>
    <b-modal
      ref="purchase-order-info-modal"
      hide-footer
      :titleHtml="modalTitle"
      size="xl"
    >
      <b-skeleton-wrapper :loading="!renderComponent">
        <template #loading>
          <b-card>
            <b-row class="mb-5">
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-skeleton
                      animation="fade"
                      width="30%"
                      class="mb-4"
                    ></b-skeleton>
                    <b-skeleton
                      type="input"
                      animation="fade"
                      width="100%"
                      class="mb-4"
                    ></b-skeleton>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8">
                <b-skeleton
                  animation="fade"
                  width="40%"
                  class="mb-4"
                ></b-skeleton>
              </b-col>
              <b-col md="4">
                <div class="d-flex justify-content-end lable-size">
                  <b-skeleton
                    animation="fade"
                    width="50%"
                    class="mb-4"
                  ></b-skeleton>
                </div>
              </b-col>
            </b-row>
            <b-skeleton-table
              :rows="4"
              :columns="10"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </b-card>
        </template>
        <b-row class="mb-5">
          <b-col cols="6">
            <POCommonInfo
              :mainModel="mainModel"
              :parentPO="false"
              v-if="renderComponent"
            />
          </b-col>
          <!-- INFO VAT INPUT CONTROLS -->
          <b-col lg="3" md="3" sm="12" v-if="renderComponent">
            <b-form-group :disabled="true">
              <label class="font-weight-bold">Doanh nghiệp:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.companyId"
                :options="listCompany"
                size="sm"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group :disabled="true">
              <label class="font-weight-bold">Hình thức thanh toán:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.importType"
                :options="listImportType"
                size="sm"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group :disabled="true">
              <label style="margin-bottom: 0.75rem" class="font-weight-bold"
                >Chiết khấu:</label
              >
              <b-row>
                <b-col class="pr-0" cols="5">
                  <b-form-select
                    class="select-style"
                    v-model="mainModel.discountType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                </b-col>
                <b-col class="pl-0" cols="7">
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    v-model="mainModel.discountAmount"
                    placeholder="Chiết khấu"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <POVat
              :editable="false"
              :mainModel="mainModel"
              v-if="renderComponent"
            />
          </b-col>
          <!-- PAYMENT INPUT CONTROLS -->
          <b-col lg="3" md="3" sm="12">
            <POPayment
              :editable="false"
              :mainModel="mainModel"
              v-if="renderComponent"
            />
          </b-col>
        </b-row>
        <b-row v-if="renderComponent">
          <b-col md="8">
            <span class="font-weight-bolder lable-size"
              >Danh sách sản phẩm:</span
            >
          </b-col>
          <b-col md="4">
            <b-checkbox
              v-model="mainModel.isVAT"
              class="d-flex justify-content-end lable-size"
              :disabled="true"
              >Đơn giá đã bao gồm VAT
            </b-checkbox>
          </b-col>
        </b-row>
        <b-table
          :fields="fieldProduct"
          :items="mainModel.details"
          hover
          bordered
          class="mt-4"
          v-if="renderComponent"
        >
          <template v-slot:cell(productName)="row">
            <span
              v-text="row.item.barCode"
              style="font-size: 11px"
              v-show="row.item.barCode"
            ></span>
            <br />
            <span
              v-text="row.item.productCode"
              style="font-size: 12px"
              v-show="row.item.productCode"
            ></span>
            <br />
            <span
              v-text="row.item.productName"
              style="white-space: normal; color: #3699ff"
            ></span>
          </template>
        </b-table>
      </b-skeleton-wrapper>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import { PURCHASE_ORDER_TYPE, TIME_TRIGGER } from '../../../utils/constants';
import POPayment from '../purchase-orders/POPayment';
import POVat from '../purchase-orders/POVat';
import POCommonInfo from '../purchase-orders/POCommonInfo';
export default {
  data() {
    return {
      modalTitle: '',
      mainModel: null,
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      listImportType: PURCHASE_ORDER_TYPE,
      listCompany: [],
      fieldProduct: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'prevUnitPrice',
          label: 'Giá nhấp nhất',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'salePowerWeek',
          label: 'Sức bán tuần',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
        },
        {
          key: 'salePowerMonth',
          label: 'Sức bán tháng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'SL',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantityApprove',
          label: 'SL đáp ứng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá (VAT)',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          thStyle: { fontWeight: 600, color: '#181c32', width: '11%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'salePrice',
          label: 'Đơn giá bán',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
      ],
      renderComponent: false,
    };
  },
  methods: {
    initMainModel() {
      return {
        id: null,
        code: '',
        providerId: null,
        providerName: '',
        description: '',
        storeId: null,
        storeName: '',
        discountAmount: 0,
        discountType: 1,
        vatAmount: 0,
        vatType: 2,
        cashAmount: 0,
        cashAccountCode: '',
        transferAmount: 0,
        transferAccountCode: '',
        payDate: '',
        vatCode: '',
        vatDate: '',
        createdAt: '',
        createdBy: '',
        importType: 1,
        importReason: '',
        companyId: null,
        inChargeEmployeeId: null,
        inChargeEmployeeName: '',
        isVAT: true,
        details: [],
        totalAmount: 0,
      };
    },
    showModal: function (code) {
      this.renderComponent = false;
      this.mainModel = this.initMainModel();
      this.fetchCompany();
      this.getInfoById(code);
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Thông tin PO mã: <a class='text-primary'>#${code}</a></span>
              </div>`;
      this.$refs['purchase-order-info-modal'].show();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getInfoById(code) {
      ApiService.get(`purchase-order/${code}`)
        .then(({ data }) => {
          this.mainModel.code = data.data.code ? data.data.code : '';
          this.mainModel.description = data.data.description
            ? data.data.description
            : '';
          this.mainModel.importReason = data.data.importReason
            ? data.data.importReason
            : '';
          this.mainModel.storeName = data.data.storeName
            ? data.data.storeName
            : '';
          this.mainModel.storeId = data.data.storeId;
          this.mainModel.providerName = data.data.providerName
            ? data.data.providerName
            : '';
          this.mainModel.providerId = data.data.providerId;
          this.mainModel.createdBy = data.data.createdByName
            ? data.data.createdByName
            : '';
          this.mainModel.inChargeEmployeeName = data.data.inChargeEmployeeName
            ? data.data.inChargeEmployeeName
            : '';
          this.mainModel.inChargeEmployeeId = data.data.inChargeEmployeeId;
          this.mainModel.companyId = data.data.companyId;
          this.mainModel.createdAt = data.data.createdAt
            ? moment(data.data.createdAt).format('HH:mm:ss DD/MM/YYYY')
            : '';
          this.mainModel.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.mainModel.vatAmount = data.data.vatAmount
            ? data.data.vatAmount
            : 0;
          this.mainModel.isVAT = data.data.isVAT;
          this.mainModel.discountType = data.data.discountType
            ? data.data.discountType
            : 1;
          this.mainModel.vatType = data.data.vatType ? data.data.vatType : 2;
          this.mainModel.cashAmount = data.data.cashAmount
            ? data.data.cashAmount
            : 0;
          this.mainModel.cashAccountCode = data.data.cashAccountCode
            ? data.data.cashAccountCode
            : '';
          this.mainModel.transferAmount = data.data.transferAmount
            ? data.data.transferAmount
            : 0;
          this.mainModel.transferAccountCode = data.data.transferAccountCode
            ? data.data.transferAccountCode
            : '';
          this.mainModel.payDate = data.data.payDate
            ? moment(data.data.payDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.vatDate = data.data.vatDate
            ? moment(data.data.vatDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.vatCode = data.data.vatCode ? data.data.vatCode : '';
          this.mainModel.importType = data.data.type ? data.data.type : 1;
          this.mainModel.details = data.data.details.map((item) => {
            const totalAmount =
              item.quantity * item.unitPrice - item.discountAmount;
            return {
              ...item,
              totalAmount: totalAmount,
            };
          });
          this.mainModel.totalAmount = data.data.totalAmount;
          setTimeout(() => {
            this.renderComponent = true;
          }, TIME_TRIGGER);
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchCompany: async function () {
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Chọn doanh nghiệp',
        });
      });
    },
  },
  components: {
    POPayment,
    POVat,
    POCommonInfo,
  },
};
</script>
